import { Link } from "gatsby"
import React from "react"
import { useInView } from "react-intersection-observer"

interface Props {
  title: string
  text: string
  className?: string
}

const DarkCard = ({ title, text, className }: Props) => {
  const { ref, inView, entry } = useInView({
    threshold: 0,
  })

  return (
    <div
      className={`dark-card ${className} ${inView ? "visible" : ""}`}
      ref={ref}
    >
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  )
}

export default DarkCard
