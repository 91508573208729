import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form } from "react-bootstrap"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import axios from "axios"
import { GiHealthIncrease, GiBookshelf } from "react-icons/gi"
import { FaTractor } from "react-icons/fa"
import CircleCard from "../../components/cards/circlecard"
import DarkCard from "../../components/cards/darkcard"

const africaresearch = require("../../images/africaresearch.jpg")
const healthtech = require("../../images/healthtech.jpg")
const edutech = require("../../images/edutech.jpg")
const thinking = require("../../images/thinking.jpg")
const agtech = require("../../images/agtech.jpg")
const africahealthcare = require("../../images/africahealthcare.jpg")
const africaeducation = require("../../images/africaeducation.jpg")
const africaindustrial = require("../../images/africaindustrial.jpg")

const hostUrl = process.env.GATSBY_BACKEND_HOST

const AfricaPage = () => {
  const [formState, setFormState] = useState({
    validated: false,
    submitted: false,
    success: false,
    error: "",
    email: "",
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      event.stopPropagation()
      setFormState({ ...formState, validated: true })

      axios
        .post(`${hostUrl}/africa-newsletter/add-contact`, {
          email: formState.email,
        })
        .then(res => {
          console.log(res)
          setFormState({ ...formState, submitted: true, success: true })
        })
        .catch(err => {
          console.log(err)
          setFormState({
            ...formState,
            submitted: true,
            error: "Uh oh, there was a problem.",
          })
        })
    }

    setFormState({ ...formState, validated: true })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, email: e.target.value })
  }

  return (
    <Layout>
      <SEO title="Africa" description="" lang="en" meta={[]} />
      {/* Hero Section */}
      <section className="africa-hero">
        <div className="hero-text">
          <h1>Flapmax Africa</h1>
          <h2>Inspiring creativity beyond borders.</h2>
        </div>
      </section>

      {/* Dedication Section */}
      <section>
        <div className="container text-center">
          <h2>Flapmax is dedicated to Africa as a hub for innovation.</h2>

          <p className="pb-5">
            We are committed to helping organizations and individuals experience
            the impactful power of Flapmax technology. That is why we are
            developing AI supported programs in Africa, starting with areas like
            healthcare, education, agriculture and industrial.
          </p>
        </div>
      </section>

      {/* Healthcare Section */}
      <section
        className="full healthcare white-text"
        style={{ backgroundImage: `url(${africahealthcare})` }}
      >
        <div className="container">
          <div className="row">
            <DarkCard
              title="Let's work together to keep our community healthy."
              text="Health is an ever growing challenge all over the world. Eventualities in Africa have brought to light different challenges that are faced by the continent and its partners. In order to beat the curve, Flapmax is partnering with medics to improve on modular sciences research."
              className="col-lg-6"
            />
          </div>
        </div>
      </section>

      {/* Education Section */}
      <section
        className="full education white-text"
        style={{ backgroundImage: `url(${africaeducation})` }}
      >
        <div className="container">
          <div className="row justify-content-end">
            <DarkCard
              title="Education is the fundamental force behind innovation."
              text="Education is the building blocks of every other civilization. At Flapmax we are providing the learners with a host of resources to improve on their learning curve. This will be followed up with opportunities in Flapmax FAST Accelerator Program to improve on market visibility."
              className="col-lg-6"
            />
          </div>
        </div>
      </section>

      {/* Industrial Section */}
      <section
        className="full industrial white-text"
        style={{ backgroundImage: `url(${africaindustrial})` }}
      >
        <div className="container">
          <div className="row">
            <DarkCard
              title="Creating better solutions for things we already do."
              text="Flapmax provides our clients with all round platform for all your edge computing needs. We have developed special programs for some of the fundamental factors affecting Africa including, Access to Health Care, Education, Agriculture and Industrial Automations. These are our flagship projects in our ambitious Africa strategy."
              className="col-lg-6"
            />
          </div>
        </div>
      </section>

      {/* Molecular Section */}
      <section className="molecular-section bg-darkened my-8">
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-lg-4">
              <img src={africaresearch} className="img-fluid" alt="" />
            </div>

            <div className="col-lg-6 text-col">
              <h2>Research in Molecular Science</h2>

              <p>
                Drug discovery and population-wide study for a growing African
                population
              </p>
              <div className="button-div">
                <Link to="/africa/laboratory">
                  <Button variant="secondary">Learn More</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Accelerator Section */}
      <section className="accelerator-section bg-blue-gradient">
        <div className="container">
          <div className="row text-center mb-2">
            <h3 className="bold">Coming Soon Spring 2022</h3>
          </div>
          <div className="row d-flex justify-content-center my-5">
            <h2>FAST ACCELERATOR FOR AFRICAN STARTUPS</h2>

            <h3 className="text-center my-3">
              Coworking Office Space | High Speed Internet | Business Networking
            </h3>
            <div className="button-div mt-5">
              <Link to="https://fastaccelerator.com">
                <Button variant="secondary" size="lg">
                  Learn More
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Office Space Section */}
      <section className="industrytech-section bg-blue">
        <div className="container">
          <h2 className="text-center">Office Space</h2>

          <h3 className="mt-3 mb-8 text-center">
            We provide office space to fuel African startups innovating in key
            industries.
          </h3>
          <div className="row d-flex justify-content-center">
            <div className="my-3 col-md-4 card m-3 p-3">
              <div className="img-div">
                <img alt="" className="img-fluid" src={healthtech} />
              </div>
              <div className="card-text">
                <h3 className="my-3">Healthcare</h3>

                <p>Enrich the vitality of your community.</p>
              </div>
            </div>

            <div className="my-3 col-md-4 card m-3 p-3">
              <div className="img-div">
                <img
                  alt=""
                  className="img-fluid"
                  src={edutech}
                  style={{ paddingBottom: "12px" }}
                />
              </div>
              <div className="card-text">
                <h3 className="my-3">Education</h3>
                <p>Ignite the knowledge in every student.</p>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="my-3 col-md-4 card m-3 p-3">
              <div className="img-div">
                <img alt="" className="img-fluid" src={agtech} />
              </div>

              <div className="card-text">
                <h3 className="my-3">Agriculture &amp; Industrial</h3>

                <p>Empower everyone. Big or small.</p>
              </div>
            </div>
            <div className="my-3 col-md-4 card m-3 p-3">
              <div className="img-div">
                <img alt="" className="img-fluid" src={thinking} />
              </div>

              <div className="card-text">
                <h3 className="my-3">More Areas</h3>

                <p>Imagine the unthinkable. Innovate.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Newsletter */}
      <section className="bg-darkened">
        <div className="container">
          <div className="row">
            <div className="col">
              <h3 className="text-center mb-5">
                Sign up to receive news &amp; updates from the field about the
                impact you're making in the world.
              </h3>
              <Form
                className="d-flex justify-content-center"
                noValidate
                validated={formState.validated}
                onSubmit={handleSubmit}
              >
                <Form.Group controlId="formBasicFirstName">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={formState.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <div className="button-div mx-3">
                  {formState.submitted ? (
                    formState.success ? (
                      <Button variant="success">Success</Button>
                    ) : (
                      <Button variant="danger">Error</Button>
                    )
                  ) : (
                    <Button type="submit">Submit</Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AfricaPage
